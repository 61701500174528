
@import "../../variables.less";
@import "../../resource/common.less";
.camera {
  margin-top: @smallSpace;
  height: 370px;
  flex-shrink: 0;
}

.page {
  margin-top: @smallSpace;
}

.mainContent2 {
  .left {
    height: 52%;
    width: calc(100% - 20px);
    border-radius: 4px;
    background:@contentBg;
    box-shadow: 0 0 10px 1px @boxshadow;
    margin: 10px;
    .video_wrap {
        width: 98%;
        height: calc(100% - 40px);
        margin: auto;
    }
    .linshi_img{
        width: 100%;
        height: 100%;
    }
  }

  .right {
    height: calc(48% - 30px);
    width: calc(100% - 20px);
    border-radius: 4px;
    background:@contentBg;
    box-shadow: 0 0 10px 1px @boxshadow;
    margin: 10px;
    display: flex;
    justify-content: center;
    .left-item {
      flex: 3;
      padding-left: 10px;
    }
    .right-item {
      flex: 4;
    }
    .title-right {
      margin-top: 5%;
      margin-bottom: 4%;
    }

    .number {
      display: flex;
      flex-direction: row;
      margin-right: 10px;
      margin-bottom: 6px;

      .num-info {
        flex: 1;
        margin-right: 4%;
        padding: 8px 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .dot {
        width: 13px;
        height: 13px;
        background: #4261ED;
        border-radius: 50%;
        margin-right: 2px;
      }

      .dotred {
        background: #EF9F24;
      }
    }

    #echart {
      width: 100%;
      height: calc(100% - 90px);
    }

    .title_search {
        display: flex;
        justify-content: space-between;
        position:relative;
        .col-img {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          background: @colorGary;
          padding: 5px 5px;
          border-radius: 5px;
          text-align: center;
            font-size: 14px;
          position:absolute;
          top: 5px;
          right: 15px;
            color:#fff;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
        }

        .no-col {
          background: @baseColor;
        }
    }
    .videoL {
      width: 100%;
      height: 100%;
      .collect {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 0 20px;
        .floor_wrap{
            font-weight: 600;
            font-size: 16px;
            &>span:nth-child(1){
                color:#4261ED;
            }
            &>span:nth-child(2){
                color:#EF9F24;
            }
        }
      }
      .video-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: calc(100% - 100px);
        overflow: auto;
        margin-top: 5px;

        .list-box {
          width: calc(33% - 12px);
          margin: 5px;
          height: 100px;
          border-radius: 5px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .isc {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 2px;
            right: 2px;
            cursor:pointer;
          }

          p {
            position: absolute;
            left: 2px;
            bottom: 10px;
            width: 70%;
            white-space: wrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            /*行数*/
            color:#fff;
          }
          .box_title_bg{
            font-size: 12px;
            // background: #f5f5f5;
          }

          .line {
            color: #fff;
            position: absolute;
            top: 2px;
            left: 2px;
            background: #2bc76c;
            border-radius: 4px;
            font-size: 12px;
            padding: 1px 2px;
          }
          .un_line{
            background: red;
          }
        }
        .active_box{
            outline: 3px solid @baseColor;
        }
      }
    }
  }
}

#chart {
  width: 600px;
  height: 400px;
}
