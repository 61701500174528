.mainContent {
  width: 400px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.camera {
  margin-top: 4px;
  height: 370px;
  flex-shrink: 0;
}
.page {
  margin-top: 4px;
}
.mainContent2 .left {
  height: 52%;
  width: calc(100% - 20px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.mainContent2 .left .video_wrap {
  width: 98%;
  height: calc(100% - 40px);
  margin: auto;
}
.mainContent2 .left .linshi_img {
  width: 100%;
  height: 100%;
}
.mainContent2 .right {
  height: calc(48% - 30px);
  width: calc(100% - 20px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
  display: flex;
  justify-content: center;
}
.mainContent2 .right .left-item {
  flex: 3;
  padding-left: 10px;
}
.mainContent2 .right .right-item {
  flex: 4;
}
.mainContent2 .right .title-right {
  margin-top: 5%;
  margin-bottom: 4%;
}
.mainContent2 .right .number {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
  margin-bottom: 6px;
}
.mainContent2 .right .number .num-info {
  flex: 1;
  margin-right: 4%;
  padding: 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContent2 .right .number .dot {
  width: 13px;
  height: 13px;
  background: #4261ED;
  border-radius: 50%;
  margin-right: 2px;
}
.mainContent2 .right .number .dotred {
  background: #EF9F24;
}
.mainContent2 .right #echart {
  width: 100%;
  height: calc(100% - 90px);
}
.mainContent2 .right .title_search {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.mainContent2 .right .title_search .col-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #788CA1;
  padding: 5px 5px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  top: 5px;
  right: 15px;
  color: #fff;
  cursor: pointer;
}
.mainContent2 .right .title_search .col-img img {
  width: 14px;
  height: 14px;
}
.mainContent2 .right .title_search .no-col {
  background: #4261ED;
}
.mainContent2 .right .videoL {
  width: 100%;
  height: 100%;
}
.mainContent2 .right .videoL .collect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
}
.mainContent2 .right .videoL .collect .floor_wrap {
  font-weight: 600;
  font-size: 16px;
}
.mainContent2 .right .videoL .collect .floor_wrap > span:nth-child(1) {
  color: #4261ED;
}
.mainContent2 .right .videoL .collect .floor_wrap > span:nth-child(2) {
  color: #EF9F24;
}
.mainContent2 .right .videoL .video-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100% - 100px);
  overflow: auto;
  margin-top: 5px;
}
.mainContent2 .right .videoL .video-list .list-box {
  width: calc(33% - 12px);
  margin: 5px;
  height: 100px;
  border-radius: 5px;
  position: relative;
}
.mainContent2 .right .videoL .video-list .list-box img {
  width: 100%;
  height: 100%;
}
.mainContent2 .right .videoL .video-list .list-box .isc {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
}
.mainContent2 .right .videoL .video-list .list-box p {
  position: absolute;
  left: 2px;
  bottom: 10px;
  width: 70%;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /*行数*/
  color: #fff;
}
.mainContent2 .right .videoL .video-list .list-box .box_title_bg {
  font-size: 12px;
}
.mainContent2 .right .videoL .video-list .list-box .line {
  color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #2bc76c;
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 2px;
}
.mainContent2 .right .videoL .video-list .list-box .un_line {
  background: red;
}
.mainContent2 .right .videoL .video-list .active_box {
  outline: 3px solid #4261ED;
}
#chart {
  width: 600px;
  height: 400px;
}
